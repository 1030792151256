$c-primary: #282828;
$c-secandary: #282828;
$dots: false;
$body-color-overrided: #282828;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$flow-v2-bg-header-overrided: #282828;
$heading-color-overrided: #282828;
$base-font-family: 'Roboto', sans-serif;
$font-family-heading: 'Bankwest', sans-serif;

      @font-face {
        font-family: 'Bankwest';
        src: url('/assets-mobile/fonts/Bankwest-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Bankwest';
        src: url('/assets-mobile/fonts/Bankwest-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
