$c-primary: #282828;
$c-secandary: #282828;
$dots: false;
$body-color-overrided: #282828;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$flow-v2-bg-header-overrided: #282828;
$heading-color-overrided: #282828;
$base-font-family: 'Roboto', sans-serif;
$font-family-heading: 'Bankwest', sans-serif;

      @font-face {
        font-family: 'Bankwest';
        src: url('/assets-mobile/fonts/Bankwest-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Bankwest';
        src: url('/assets-mobile/fonts/Bankwest-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 1rem 1.5rem;
  width: 100%;
  height: 4.8125rem;
  box-shadow: 0px 0px 0.5rem rgba(0, 0, 0, 0.25);
}

form legend {
  line-height: 1;
}

.buttonsWrapper {
  height: 100%;

  .buttons {
    height: 100%;
    display: flex;
    justify-content: space-between;

    button {
      width: auto;
      margin: 0 7px;
      min-width: 57%;

      &:first-child {
        text-align: left;
        min-width: 0px !important;
      }
    }
  }
}

.review {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  .header {
    color: gray;
  }

  .questionsGroups {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-bottom: 100px;

    .questionsGroup {
      border-color: #f5f5f5;
      border-style: solid;
      border-radius: 5px;
      border-width: 1px;

      .questions {
        display: flex;
        flex-direction: column;
        padding: 5px;

        .question {
          .title {
          }

          .answer {
            color: gray;
          }
        }
      }

      .edit {
        padding: 10px;
        border-top-style: solid;
        border-top-width: 1px;
        border-radius: 5px;
        border-top-color: #f5f5f5;
        text-align: right;
        text-decoration: underline;
      }
    }

    .questionsGroup:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .importantInfo {
    padding: 10px;
  }
}
