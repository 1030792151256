$c-primary: #282828;
$c-secandary: #282828;
$dots: false;
$body-color-overrided: #282828;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$flow-v2-bg-header-overrided: #282828;
$heading-color-overrided: #282828;
$base-font-family: 'Roboto', sans-serif;
$font-family-heading: 'Bankwest', sans-serif;

      @font-face {
        font-family: 'Bankwest';
        src: url('/assets-mobile/fonts/Bankwest-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Bankwest';
        src: url('/assets-mobile/fonts/Bankwest-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  height: 100%;

  .buttons {
    display: flex;
    justify-content: space-between;
    height: 100%;

    button {
      width: auto;
      height: 100%;
      min-width: 57%;
      white-space: break-spaces;
      line-height: 1;

      &:first-child {
        text-align: left;
        min-width: min-content !important;
      }

      + button {
        margin-left: .5rem;
      }
    }
  }

  .btnFull {
    width: 100%;
    height: 100%;

    button {
      width: 100%;
      height: 100%;
    }
  }
}
